$position-top: 2rem;
$position-bottom: 2rem;
$position-left: 2rem;
$position-right: 2rem;
$container-padding: 0.5rem;

.container {
  position: fixed;
  z-index: 999;
  background: transparent;
  padding: $container-padding;

  max-width: 35rem;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100% - 2rem);

  &__right-top {
    right: calc($position-right - $container-padding);
    top: calc($position-top - $container-padding);
  }

  &__left-top {
    left: calc($position-left - $container-padding);
    top: calc($position-top - $container-padding);
  }

  &__right-bottom {
    right: calc($position-right - $container-padding);
    bottom: calc($position-bottom - $container-padding);
  }

  &__left-bottom {
    left: calc($position-left - $container-padding);
    bottom: calc($position-bottom - $container-padding);
  }
}

.container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.notification {
  padding: 0.8rem 1rem 0.7rem 1rem;
  background-color: transparent;
  box-shadow: 0px 4px 20px rgba(19, 123, 198, 0.25);
  border-radius: 0.9rem;
  min-height: 6rem;
  max-width: 35rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__text-box {
    & > p {
      &:not(:last-child) {
        padding-bottom: 0.5rem;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }

  &__info {
    background-color: var(--primary-blue);
  }
  &__error {
    background-color: var(--red);
  }
  &__success {
    background-color: var(--green);
  }
  &__warning {
    background-color: var(--amber);
  }
}

@keyframes left-to-right {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes exit-right-to-left {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes right-to-left {
  0% {
    transform: translate3d(100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes exit-left-to-right {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.enterFromLeftToRight {
  animation: left-to-right 300ms ease-in-out;
}

.enterFromRightToLeft {
  animation: right-to-left 300ms ease-in-out;
}

.exitFromLeftToRight {
  animation: exit-left-to-right 300ms ease-in-out;
}

.exitFromRightToLeft {
  animation: exit-right-to-left 300ms ease-in-out;
}

