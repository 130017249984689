.uppercase {
  text-transform: uppercase;
}

.ellipsis {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.underlined {
  border-bottom: 2px solid var(--primary-blue);
  padding-bottom: 0.4rem;
  display: inline-block;
}

.white {
  color: var(--base-white) !important;
}

.blue {
  color: var(--primary-blue) !important;
}

.secondary-blue {
  color: var(--secondary-blue);
}

.grey {
  color: var(--grey) !important;
}

.green {
  color: var(--green) !important;
}

.text-color {
  color: var(--text-color);
}

.red {
  color: var(--red) !important;
}

.black {
  color: var(--black) !important;
}
