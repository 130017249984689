$closed-width: 10rem;
$opened-width: 34rem;
$middle-width: 18rem;

.btn {
  background-color: transparent;
  border: none;
  outline: none;
}

.closed {
  width: $closed-width;
}

.open {
  width: $opened-width;
}

.container {
  min-height: 70rem;
  height: 100%;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  background-color: var(--base-white);
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.5s ease-in-out;
  border-left: 1px solid var(--background-color);

  &__closed {
    width: auto;
  }

  &__open {
    width: auto;
  }

  &__logo {
    display: flex;
    padding-top: 2rem;
    padding-left: 0;
    height: 10.4rem;
    align-items: center;
    transition: padding-left 0.5s ease-in-out;
    background-color: var(--base-white);
  }

  &__title {
    padding: 3.4rem 3.4rem 3.4rem 0;
    text-align: left;
  }

  &__log-out {
    display: flex;
    padding: 4rem 0 3.8rem 0;
    border: none;
    outline: none;
    background-color: var(--base-white);
  }
}

.nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 506px;
  overflow-y: scroll;
  max-height: 100%;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &__item {
    padding: 1rem 0;
    background-color: var(--base-white);
    display: flex;
    position: relative;

    &__container {
      cursor: pointer;
      width: 4.7rem;
      height: 4.2rem;
      border-radius: 1.2rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      position: relative;
      z-index: 2;

      &__closed {
        transition: 0.5s ease-in-out;
        background-color: var(--light-grey);
        svg > path,
        rect {
          transition: 0.5s ease-in-out;
          fill: var(--text-color);
        }
      }

      &__open {
        transition: 0.5s ease-in-out;
        background-color: var(--base-white);

        svg > path,
        rect {
          transition: 0.5s ease-in-out;
          fill: var(--grey);
        }
      }

      svg > path,
      rect {
        fill: var(--text-color);
      }

      &__selected {
        background-color: var(--primary-blue);

        svg > path,
        rect {
          fill: var(--base-white);
        }
      }
    }
  }
}

.nav__item__container__roles_icon {
  &.nav__item__container__selected {
    svg > g > path,
    svg > g > circle {
      stroke: var(--base-white);
      fill: transparent;
    }
  }
}

.nav__item__container__sales_icon {
  svg > path {
    stroke: var(--text-color);
    fill: transparent;
  }

  &.nav__item__container__selected {
    svg > path {
      stroke: var(--base-white);
      fill: transparent;
    }
  }
}

.nav__item__container__computer_icon {
  &.nav__item__container__selected {
    svg > g > path {
      stroke: var(--base-white);
      fill: transparent;
    }
  }
}

.titles {
  cursor: default;
  text-align: left;
  height: 4.2rem;
  line-height: 4.2rem;
  position: relative;
  margin-right: 3rem;
  border-bottom-right-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
  transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
  white-space: nowrap;
}

.round-btn {
  width: 2.7rem;
  height: 2.7rem;
  line-height: 2.7rem;
  background-color: var(--secondary-blue);
  border: none;
  border-radius: 50%;
  color: var(--base-white);
  position: absolute;
  top: 4.5rem;
  left: 8.9rem;
  z-index: 10;
  transition: left 0.5s ease-in-out;
}

.btn-open {
  left: 26.7rem;
}

.open {
  width: $middle-width;
}

.tooltip {
  left: 90% !important;

  &__hide {
    display: none !important;
  }
}

.small {
  width: $closed-width;
  transition: padding-left 0.5s ease-in-out;
}
.wide {
  width: $middle-width;

  transition: width 0.5s ease-in-out, opacity 0.3s ease-in-out 0.2s;
  text-align: left;

  &__none {
    transition: width 0.5s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;
    width: 0;

    svg {
      width: 0;
    }
  }
}

.right {
  transition: padding-left 0.5s ease-in-out;
  padding-left: 4rem;
}

.titles:before {
  position: absolute;
  cursor: default;
  top: 0;
  left: -5rem;
  width: 5rem;
  height: 100%;
  content: '';
  background-color: transparent;
  transition: background-color 0.5s ease-in-out;
  z-index: 1;
}

.hover:hover {
  .nav__item__container {
    background-color: var(--primary-blue);

    svg > path,
    rect {
      fill: var(--base-white);
    }

    &__sales_icon {
      svg > path {
        stroke: var(--base-white);
        fill: transparent;
      }
    }

    &__roles_icon {
      svg > g > path,
      svg > g > circle {
        stroke: var(--base-white);
        fill: transparent;
      }
    }
  }

  .titles:before {
    background-color: var(--primary-blue);
    cursor: pointer;
  }

  .titles,
  .side-line {
    background-color: var(--primary-blue);
    color: var(--base-white);
  }

  .titles__item {
    color: var(--base-white);
  }
}

.titles__item.selected {
  color: var(--base-white);
}

.line {
  background-color: var(--primary-blue);
}

.line:before {
  position: absolute;
  top: 0;
  left: -5rem;
  width: 5rem;
  height: 100%;
  content: '';
  background-color: var(--primary-blue);
  transition: background-color 0.5s ease-in-out;
  z-index: 1;
}

.pointer {
  cursor: pointer;
}

.side-line {
  width: 0.5rem;
  height: 4.2rem;
  background-color: var(--base-white);
  position: absolute;
  border-bottom-right-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
  transition: background-color 0.5s ease-in;

  &__blue {
    background-color: var(--primary-blue);
  }
}

.wrapper__sidebar {
  position: fixed;
  height: 100vh;
  z-index: 5;
}