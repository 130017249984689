:root {
	--background-color: #F3F3F3;

	--base-white: #fff;
	--primary-blue: #137BC6;
	--secondary-blue: #0471C0;
	--text-color: #45494E;
	--input-text: #000000;
	--higlight-color: rgba(214, 222, 227, 0.2);

	--white-smoke: #F6F6F6;
	--light-grey: #D6DEE3;
	--grey: #B6B7B8;
	--red: #F64141;
	--amber: #F9A825;
	--green: #00897B;
	--green-light: #008844;
	--black: #000000;
	--purple: #512DA8;
	--dim-white: #F7F8FA;
	--blue: #072BDB;
	--dark-grey: #455A64;
	--pink: #AD1457;
	--light-pink: #FFA5A5;
	--light-orange: #fff7f3;

}
