@mixin font($size, $weight, $height) {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-size: #{$size}rem;
  font-weight: $weight;
  line-height: #{$height}px;
  letter-spacing: 0px;

  color: var(--text-color);
}

// from style-guide
.title {
  @include font(1.8, 700, 18);
}

.button-bold {
  @include font(1.3, 700, 17);
}

.text {
  @include font(1.3, 500, 23);
}

.text-bold {
  @include font(1.3, 700, 23);
}

.text-small {
  @include font(1.1, 500, 23);
}

.card {
  @include font(1.8, 600, 28);
}

.button {
  @include font(1.8, 500, 17);
}

.percents {
  @include font(1.3, 600, 22);
}

.welcome-bold {
  @include font(4.8, 700, 60);
}

.title-bold {
  @include font(2.4, 700, 30);
}

