.not-supported {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  h1 {
    width: 100%;
    text-align: center;
  }
}