/* Custom properties */
:root {
  --tooltip-text-color: white;
  --tooltip-background-color: black;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

.container {
  display: flex;
  position: relative;
  padding: 0;

  &__fixed {
    position: relative;
  }
}

.hover {
  :hover + .Tooltip-Tip {
    display: block;
  }
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  padding: 1rem 1.5rem;
  border-radius: 0.9rem;
  font-size: 1.3rem;
  line-height: 2.3rem;
  z-index: 100;
  white-space: nowrap;

  &__dark {
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
  }

  &__grey {
    color: var(--text-color);
    background: var(--light-grey);
  }
}

.Tooltip-Tip.hide {
  display: none;
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: ' ';
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.top {
  transform: translateX(-50%);
  top: calc(var(--tooltip-margin) * -1.6);
  left: 50%;
}

/* CSS border triangles */
.Tooltip-Tip.top {
  &__dark::before {
    left: 50%;
    bottom: -11px;
    border-bottom: 0;
    transform: translate(calc(-50% + 5px), -100%);
    border-top-color: var(--tooltip-background-color);
  }

  &__grey::before {
    left: 50%;
    bottom: -11px;
    border-bottom: 0;
    transform: translate(calc(-50% + 5px), -100%);
    border-top-color: var(--light-grey);
  }
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  transform: translateX(-50%);
  bottom: calc(var(--tooltip-margin) * -1.6);
  left: 50%;
}

/* CSS border triangles */
.Tooltip-Tip.bottom {
  &__dark::before {
    top: 0;
    left: 50%;
    border-top: 0;
    transform: translate(calc(-50% + 5px), -100%);
    border-bottom-color: var(--tooltip-background-color);
  }

  &__grey::before {
    top: 0;
    left: 50%;
    border-top: 0;
    transform: translate(calc(-50% + 5px), -100%);
    border-bottom-color: var(--light-grey);
  }
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
}

/* CSS border triangles */
.Tooltip-Tip.right {
  &__dark::before {
    left: 0;
    top: 50%;
    border-left: 0;
    border-right-color: var(--tooltip-background-color);
    transform: translate(calc(-100% + 7px), -50%);
  }

  &__grey::before {
    left: 0;
    top: 50%;
    border-left: 0;
    border-right-color: var(--light-grey);
    transform: translate(calc(-100% + 7px), -50%);
  }
}

/* Absolute positioning */
.Tooltip-Tip.left {
  right: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
}

/* CSS border triangles */
.Tooltip-Tip.left {
  &__dark::before {
    right: 0;
    top: 50%;
    border-right: 0;
    border-left-color: var(--tooltip-background-color);
    transform: translate(calc(100%), -50%);
  }

  &__grey::before {
    right: 0;
    top: 50%;
    border-right: 0;
    border-left-color: var(--light-color);
    transform: translate(calc(100%), -50%);
  }
}

.tooltip {
  position: fixed;
  z-index: 100500;
  padding: 1rem 1.5rem;
  border-radius: 0.9rem;
  font-size: 1.3rem;
  line-height: 2.3rem;
  box-shadow: 0px 4px 16px rgba(31, 41, 55, 0.1);

  &--dark {
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    &::before {
      border-color: var(--tooltip-background-color);
    }
  }

  &--grey {
    color: var(--text-color);
    background: var(--light-grey);
    &::before {
      border-color: var(--light-grey);
    }
  }

  &::before {
    content: ' ';
    border-style: solid;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
  }

  &__right {
    &::before {
      top: 50%;
      left: 0;
      border-left: 0;
      transform: translateY(-50%);
      border-top-color: transparent;
      border-bottom-color: transparent;
    }
  }

  &__left {
    &::before {
      right: calc(0px - var(--tooltip-arrow-size));
      top: 50%;
      border-right: 0;
      border-top-color: transparent;
      border-bottom-color: transparent;
      transform: translateY(-50%);
    }
  }

  &__top {
    &::before {
      right: calc(50% - var(--tooltip-arrow-size));
      bottom: -9px;
      transform: translateY(-50%);
      border-bottom: 0;
      border-left-color: transparent;
      border-right-color: transparent;
    }
  }

  &__top-left {
    border-bottom-left-radius: 0;
    &::before {
      left: var(--tooltip-arrow-size);
      bottom: calc(0px - var(--tooltip-arrow-size));
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-right-color: transparent;
    }
  }

  &__bottom {
    &::before {
      right: calc(50% - var(--tooltip-arrow-size));
      border-top: 0;
      top: calc(0px - var(--tooltip-arrow-size));
      border-left-color: transparent;
      border-right-color: transparent;
    }
  }

  &__bottom-left {
    border-top-left-radius: 0;
    &::before {
      left: var(--tooltip-arrow-size);
      top: calc(0px - var(--tooltip-arrow-size));
      border-right-color: transparent;
      border-top-color: transparent;
      border-bottom-color: transparent;
    }
  }

  &__bottom-right {
    border-top-right-radius: 0;
    &::before {
      right: 0;
      top: calc(0px - var(--tooltip-arrow-size));
      border-left-color: transparent;
      border-bottom-color: transparent;
      border-top-color: transparent;
    }
  }
}
