.layout {
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: [sidebar-start] 10.3rem [sidebar-end content-start] 1fr [content-end];
  grid-column-gap: 7rem;
  background-color: var(--background-color);

  @media (max-width: 1400px) {
    grid-column-gap: 3rem;
  }
}

.content {
  grid-row-end: content-end;
  transition: padding-left 0.5s ease-in-out;
  padding-right: 7rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  @media (max-width: 1400px) {
    padding-right: 3rem;
  }
}

.contentMenuOpen {
  padding-left: 18rem;
}
.contentMenuClose {
  padding-left: 0;
}

.sidebar {
  grid-column-start: sidebar-start;
  grid-row-end: content-end;
  background-color: var(--background-color);
}